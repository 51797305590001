import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

const MetaTags = ({metaData}) => {
    const currUrl = useLocation();
    // useEffect(()=>{
    //     console.log('currUrl....',currUrl);
    // },[]);
    return (
        <div>
            {metaData && <Helmet>
                <title>{metaData.title}</title>
                <meta name="description" content={metaData.description} />
                <meta name="keywords" content={metaData.keyword} />
                <meta name="language" content="en-us, english" />
                <meta http-equiv="Content-Language" content="en" />
                <meta name="author" content="TrueHomes24.com" />
                <meta name="copyright" content="https://www.truehomes24.com/" />
                <meta name="robots" content="index, follow" />
                <link rel="canonical" href={"https://www.truehomes24.com"+currUrl.pathname} />
                <meta property="og:title" content={metaData.title} />
                <meta property="og:url" content={"https://www.truehomes24.com"+currUrl.pathname} />
                <meta property="og:description" content={metaData.description} />
            </Helmet>}
        </div>
    );
}

export default MetaTags;
